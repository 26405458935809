import { animated, config, useSpring } from "@react-spring/web"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { GlobalStyle } from "./components/GlobalStyles"
import { initMatomoTracking } from "./lib/MatomoTracking"

/**
╔═════════════════╗
║                 ║
║  anthes.rocks   ║
║                 ║
╚═════════════════╝
 */

const Headline = styled.h1`
  font-weight: 700;
  text-align: center;
  white-space: break-spaces;
`

const Shell = styled.main`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

const Span = styled.span`
  background-color: #7200ff;

  animation: bgColor 7s alternate infinite;

  @keyframes bgColor {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    33% {
      background-color: #ff0066;
    }
    66% {
      background-color: rgba(114, 0, 255, 1);
    }
    100% {
      background-color: rgba(0, 255, 115, 1);
    }
  }
`
const alphaNumericPlus =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!"§$%&/()=?¡“¶¢[]|{}≠¿:;_-#+*'
// const pChar2 = "═║╒╓╔╕╖╗╘╙╚╛╜╝╞╟▀▁▂▃▄▅▆▇█▉▊▋▌▍▎▏▐░▒▓▔▕▖▗▘▙▚▛▜▝▞▟"
const possibleCharacters = alphaNumericPlus

const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const Character = ({ char, index }: { char: string; index: number }) => {
  const [randomChars, setRandomChars] = useState<string[]>()

  useEffect(() => {
    let newChars = ""
    while (newChars.length < 118) {
      newChars += possibleCharacters.charAt(
        randomIntFromInterval(0, possibleCharacters.length)
      )
    }
    // Sets character array to: █, [random chars], [character] (█ or ▒ or ░)
    setRandomChars(["░", ...Array.from(newChars), char])
  }, [char])

  const { number } = useSpring({
    from: { number: 0 },
    number: randomChars?.length ?? 0,
    delay: 1500 + index * 60 + Math.random() * 25,
    config: config.gentle,
  })

  if (!randomChars) return null

  return (
    <animated.span>
      {number.to((n) => randomChars[parseInt(n.toFixed(0))])}
    </animated.span>
  )
}

const ShuffleText = ({
  text,
  inline = false,
  offset = 0,
}: {
  text: string
  inline?: boolean
  offset?: number
}) => {
  return (
    <>
      {Array.from(text).map((letter, index) => (
        <Character
          key={`${letter}${index}`}
          char={letter}
          index={index + offset * 4}
        />
      ))}
      {!inline && "\n"}
    </>
  )
}

const App = () => {
  useEffect(() => {
    document.title = "anthes.rocks"
    if (process.env.NODE_ENV === "production") {
      initMatomoTracking("1")
    }
  })
  return (
    <Shell>
      <GlobalStyle />
      <Headline aria-label="anthes.rocks">
        <ShuffleText text="╔═════════════════╗" />
        <ShuffleText text="║                 ║" offset={1} />
        <ShuffleText text="║  " offset={2} inline />
        <ShuffleText text="anthes" offset={12} inline />
        <Span>
          <ShuffleText text=".rocks" offset={18} inline />
        </Span>
        <ShuffleText text="   ║" offset={4} />
        <ShuffleText text="║                 ║" offset={5} />
        <ShuffleText text="╚═════════════════╝" offset={6} />
      </Headline>
    </Shell>
  )
}

export { App }
