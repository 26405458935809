import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  html, body, div#root {
    height:100%;
  }
  body {
    font-family: 'Fira Code', Menlo, Monaco, 'Courier New', monospace;
    line-height: 1.23;
  }

  @media (prefers-color-scheme: dark) {
    body {
      color: white;
      background-color: black;
    }
  }

  @media (prefers-color-scheme: light) {
    body {
      color: black;
      background-color: white;
    }
  }
`
