import "firacode/distr/fira_code.css"
import "modern-normalize/modern-normalize.css"
import { createRoot, hydrateRoot } from "react-dom/client"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"

const rootElement = document.getElementById("root")

if (rootElement && rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />)
} else {
  const root = createRoot(rootElement!)
  root.render(<App />)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
